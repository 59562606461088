/* GenealogyTree.css */

/* Customize scrollbar */
.genealogy-scroll::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }
  
  .genealogy-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e4e4e4;
  }
  
  .genealogy-scroll::-webkit-scrollbar-thumb {
    background: #212121;
    border-radius: 10px;
  }
  
  .genealogy-scroll::-webkit-scrollbar-thumb:hover {
    background: #d5b14c;
  }
  
  /* Styling for the genealogy tree */
  .tree ul {
    padding-top: 20px;
    position: relative;
    transition: all 0.5s;
  }
  
  .tree li {
    float: left;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 20px 5px 0 5px;
    transition: all 0.5s;
  }
  
  /* Connectors using ::before and ::after */
  .tree li::before,
  .tree li::after {
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 1px solid #ccc;
    width: 50%;
    height: 20px;
  }
  
  .tree li::after {
    right: auto;
    left: 50%;
    border-left: 1px solid #ccc;
  }
  
  /* Remove connectors from elements without siblings */
  .tree li:only-child::after,
  .tree li:only-child::before {
    display: none;
  }
  
  /* Remove space from the top of single children */
  .tree li:only-child {
    padding-top: 0;
  }
  
  /* Remove left connector from first child and right connector from last child */
  .tree li:first-child::before,
  .tree li:last-child::after {
    border: 0 none;
  }
  
  /* Adding back the vertical connector to the last nodes */
  .tree li:last-child::before {
    border-right: 1px solid #ccc;
    border-radius: 0 5px 0 0;
  }
  
  .tree li:first-child::after {
    border-radius: 5px 0 0 0;
  }
  
  /* Downward connectors from parents */
  .tree ul ul::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid #ccc;
    width: 0;
    height: 20px;
  }
  
  .tree li a {
    border: 1px solid #ccc;
    padding: 5px 10px;
    text-decoration: none;
    color: #666;
    font-family: Arial, Verdana, Tahoma;
    font-size: 11px;
    display: inline-block;
    border-radius: 5px;
    transition: all 0.5s;
  }
  
  /* Hover effects */
  .tree li a:hover,
  .tree li a:hover + ul li a {
    background: #c8e4f8;
    color: #000;
    border: 1px solid #94a0b4;
  }
  
  /* Connector styles on hover */
  .tree li a:hover + ul li::after,
  .tree li a:hover + ul li::before,
  .tree li a:hover + ul::before,
  .tree li a:hover + ul ul::before {
    border-color: #94a0b4;
  }
  