/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Slab:wght@100..900&display=swap'); */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Jost:ital,wght@0,100..900;1,100..900&family=Merienda:wght@300..900&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Yuji+Mai&display=swap');
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  font-family: "Merienda", cursive;
}
body{
  background: #ffffffe3;
}
.tree-node {
    position: relative;
    margin-bottom: 40px;
  }
  
  .tree-node img {
    border-radius: 50%;
    border: 2px solid #ddd;
  }
  
  .tree-node .line {
    position: absolute;
    top: 10px;
    left: 50%;
    height: 100%;
    width: 1px;
    background-color: #ccc;
  }
  
  .tree-node .children {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .tree-node .children .tree-node {
    margin: 0 20px;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  ::-webkit-scrollbar-track {
    background: #ffffff; 
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #f9f8f8; 
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  

  #tradingview-widget-container{
    width: 100% !important;
    border-radius: 8px;
  }

  
  



